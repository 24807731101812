import jQuery from 'jquery';
import ('jquery-ui-dist/jquery-ui')
import 'slick-carousel'
import 'select2'
import 'bootstrap'
import { Loader } from "@googlemaps/js-api-loader"

export class LibraryLoader {

    static _instance

    /**
     * Singleton pattern for library loader
     * @returns instance
     */
    static getInstance() {
        if (!LibraryLoader._instance) {
            LibraryLoader._instance = new LibraryLoader();
        }
        return LibraryLoader._instance
    }

    constructor() {
        console.log("LibraryLoader loaded")
        this.load()
    }

    load() {

        window._ = require('lodash');

        /**
         * We'll load jQuery and the Bootstrap jQuery plugin which provides support
         * for JavaScript based Bootstrap features such as modals and tabs. This
         * code may be modified to fit the specific needs of your application.
         */

        try {
            window.Popper = require('popper.js').default;
            window.$ = window.jQuery = jQuery;
            //require('jquery-ui-dist/jquery-ui.js');

            //require('bootstrap');
            require('@fancyapps/fancybox');
        } catch (e) {}

        /**
         * We'll load the axios HTTP library which allows us to easily issue requests
         * to our Laravel back-end. This library automatically handles sending the
         * CSRF token as a header based on the value of the "XSRF" token cookie.
         */

        window.axios = require('axios');
        window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

        /**
         * Echo exposes an expressive API for subscribing to channels and listening
         * for events that are broadcast by Laravel. Echo and event broadcasting
         * allows your team to easily build robust real-time web applications.
         */


        /**
         * Import google api
         */


    }

    loadGoogleAsync() {
        const googleloader = new Loader({
            apiKey: "AIzaSyC79-MV0Weaof8ENkcLh30NxVsMGo1SK9Q",
            version: "weekly",
        });
        return googleloader.load()
    }
}
