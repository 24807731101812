import { ToastManager } from "./ToastManager"

/**
 * @name FamaUtils
 * @author - LoP
 * @refactor - Ibsen
 * @version 1.0
 */
export class FamaUtils {

    /** FamaUtils instance */
    static _instance

    /**
     * Singleton method
     * @returns instance
     */
    static getInstance() {
        FamaUtils._instance = (window.fama) ? window.fama.utils ?? null : null ;
        if (!FamaUtils._instance) {
            window.fama = window.fama || {}
            FamaUtils._instance = new FamaUtils()
            window.fama.utils = FamaUtils._instance // global accessor
        }

        return FamaUtils._instance
    }

    //** handle timers */
    _timers = []
    _debug = true
    _traces = false
    toast = ToastManager.getInstance();

    /**
     * Constructor
     */
    constructor() {
        this.debug("FamaUtils loaded");
        this.jQueryExtensions()
        this.addGlobalExtensions();
    }

    debug(o) {
        if(this._debug) {
            console.log(o)
            if (this._traces) console.trace()
        }
    }

    /**
     * Redirect console output in devices without console
     * @param {*} item
     */
    alert( item ) {
        if (console) {
            this.debug(item)
        }
        else {
            alert(item);
        }
    }

    /**
     *
     * @param {Function} func
     * @param {String} type $ if it is JQuery extension
     * @returns {Boolean}
     */
    isLoad(func, type) {
        if (type == "$") {
            return (typeof jQuery.fn[func] != "undefined");
        }
        else {
            return (typeof window[func] != "undefined");
        }
    }

    /**
     *
     * @param {string|array} selector
     * @param {string} operator
     * @returns
     */
    exists(selectors, operator = 'and'){
        let exists;
        selectors = [].concat(selectors)

        // And
        if(operator === 'and') {
            exists = true;
            selectors.every(selector => {
                exists = ($(selector).length > 0)
                return exists
            });
        }

        //or
        if(operator === 'or') {
            exists = false;
            selectors.every(selector => {
                exists = ($(selector).length > 0)
                return !exists
            });
        }
        return exists;
    }

    /**
     * Decorator OR
     * @param {string|array} selectors
     */
    existsOr(selectors) {
        return this.exists(selectors, 'or')
    }

    /**
     * Decorator AND
     * @param {string|array} selectors
     */
    existsAnd(selectors) {
        return this.exists(selectors, 'and')
    }

    /**
     *
     * @param {Object} v
     * @returns {Boolean}
     */
    is(v) {
        return typeof v != "undefined" || v != null;
    }

    /**
     * Check if email is valid
     * @param {String} email
     * @returns {Boolean}
     */
    isEmailValid(email) { //usa fama
        return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);

    };


    /**
     * Check if phone is valid
     * @param {String} email
     * @returns {Boolean}
     */
    isTlfValid(email) { //usa fama
        return /^\+?[0-9\s\-]+$/.test(tlf);
    };

    /**
     * Checks browser's userAgent to determine if it is mobile browser
     * @returns {Boolean}
     */
    isMobile() {
        return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase()) || $('body').outerWidth() <= 768;
    }

    /**
     *
     * @returns
     */
    isAndroid() {
        return /android/i.test(navigator.userAgent);
    }

    /**
     *
     * @returns
     */
    isIOS() {
        return /iP(hone|od|ad)/.test(navigator.platform);
    }

    /**
     *
     * @returns
     */
    isIPad() {
        return /iPad/.test(navigator.platform);
    }

    /**
     *
     * @returns
     */
    IOSversion() {
        if (!/iP(hone|od|ad)/.test(navigator.platform)) return false;
        var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
        return parseInt(v[1], 10);
    }

    /**
     * Standarize ajax call
     * @param {*} module
     * @param {*} action
     * @param {*} options
     * @returns
     */
    ajaxCall(module, action, options) {
        if (typeof options != "object") {
            var options = {};
        }
        if (typeof options.data != "object") {
            options.data = {};
        }
        if (typeof options.async == "undefined") {
            options.async = true;
        }
        var language = $("html").attr("lang");

        var resp = null;
        $.ajax({
            url: "/ajax.php?module=" + module + "&action=" + action + "&language=" + language,
            data: options.data,
            type: "post",
            async: options.async,
            dataType: "json",
            beforeSend: () => {
                if (options.beforeSend) {
                    options.beforeSend();
                }
            },
            context:this,
            success: (r) => {
                if (r.status == 'ok') {
                    if (options.target) {
                        if (typeof options.target != "object") {
                            options.target = $(options.target);
                        }
                        if (typeof options.filter != "undefined") {
                            options.target.html($(r.html).filter(options.filter));
                        }
                        else {
                            options.target.html(r.html);
                        }
                    }
                    if (options.onSuccess) {
                        resp = options.onSuccess(r);
                    }
                    if (r.message) {
                        this.toast.log(r.message);
                    }
                }
                else if (r.status == 'redirect') {
                    document.location.href = r.to;
                }
                else if (r.status == 'error') {
                    if (r.message) {
                        this.toast.log(r.message);
                    }
                }
                else {
                    this.debug(r)
                    this.toast.log("ERROR: UNKNOWN ERROR");
                }
            },
        });
        return resp;
    }

    /**
     *
     * @param {*} f_haystack
     * @param {*} f_needle
     * @param {*} f_offset
     * @returns
     */
    stripos(f_haystack, f_needle, f_offset) {
        var haystack = (f_haystack + '').toLowerCase();
        var needle = (f_needle + '').toLowerCase();
        var index = 0;

        if ((index = haystack.indexOf(needle, f_offset)) !== -1) {
            return index;
        }
        return false;
    }

    /**
     * Remove accents an replace with "accentless" letter
     *
     * @param {*} str
     * @returns {String}
     */
    removeAccents(str) {
        var map = {
            'À': 'A',
            'Á': 'A',
            'Â': 'A',
            'Ã': 'A',
            'Ä': 'A',
            'Å': 'A',
            'Æ': 'AE',
            'Ç': 'C',
            'È': 'E',
            'É': 'E',
            'Ê': 'E',
            'Ë': 'E',
            'Ì': 'I',
            'Í': 'I',
            'Î': 'I',
            'Ï': 'I',
            'Ð': 'D',
            'Ñ': 'N',
            'Ò': 'O',
            'Ó': 'O',
            'Ô': 'O',
            'Õ': 'O',
            'Ö': 'O',
            'Ø': 'O',
            'Ù': 'U',
            'Ú': 'U',
            'Û': 'U',
            'Ü': 'U',
            'Ý': 'Y',
            'ß': 's',
            'à': 'a',
            'á': 'a',
            'â': 'a',
            'ã': 'a',
            'ä': 'a',
            'å': 'a',
            'æ': 'ae',
            'ç': 'c',
            'è': 'e',
            'é': 'e',
            'ê': 'e',
            'ë': 'e',
            'ì': 'i',
            'í': 'i',
            'î': 'i',
            'ï': 'i',
            'ñ': 'n',
            'ò': 'o',
            'ó': 'o',
            'ô': 'o',
            'õ': 'o',
            'ö': 'o',
            'ø': 'o',
            'ù': 'u',
            'ú': 'u',
            'û': 'u',
            'ü': 'u',
            'ý': 'y',
            'ÿ': 'y',
            'Ā': 'A',
            'ā': 'a',
            'Ă': 'A',
            'ă': 'a',
            'Ą': 'A',
            'ą': 'a',
            'Ć': 'C',
            'ć': 'c',
            'Ĉ': 'C',
            'ĉ': 'c',
            'Ċ': 'C',
            'ċ': 'c',
            'Č': 'C',
            'č': 'c',
            'Ď': 'D',
            'ď': 'd',
            'Đ': 'D',
            'đ': 'd',
            'Ē': 'E',
            'ē': 'e',
            'Ĕ': 'E',
            'ĕ': 'e',
            'Ė': 'E',
            'ė': 'e',
            'Ę': 'E',
            'ę': 'e',
            'Ě': 'E',
            'ě': 'e',
            'Ĝ': 'G',
            'ĝ': 'g',
            'Ğ': 'G',
            'ğ': 'g',
            'Ġ': 'G',
            'ġ': 'g',
            'Ģ': 'G',
            'ģ': 'g',
            'Ĥ': 'H',
            'ĥ': 'h',
            'Ħ': 'H',
            'ħ': 'h',
            'Ĩ': 'I',
            'ĩ': 'i',
            'Ī': 'I',
            'ī': 'i',
            'Ĭ': 'I',
            'ĭ': 'i',
            'Į': 'I',
            'į': 'i',
            'İ': 'I',
            'ı': 'i',
            'Ĳ': 'IJ',
            'ĳ': 'ij',
            'Ĵ': 'J',
            'ĵ': 'j',
            'Ķ': 'K',
            'ķ': 'k',
            'Ĺ': 'L',
            'ĺ': 'l',
            'Ļ': 'L',
            'ļ': 'l',
            'Ľ': 'L',
            'ľ': 'l',
            'Ŀ': 'L',
            'ŀ': 'l',
            'Ł': 'L',
            'ł': 'l',
            'Ń': 'N',
            'ń': 'n',
            'Ņ': 'N',
            'ņ': 'n',
            'Ň': 'N',
            'ň': 'n',
            'ŉ': 'n',
            'Ō': 'O',
            'ō': 'o',
            'Ŏ': 'O',
            'ŏ': 'o',
            'Ő': 'O',
            'ő': 'o',
            'Œ': 'OE',
            'œ': 'oe',
            'Ŕ': 'R',
            'ŕ': 'r',
            'Ŗ': 'R',
            'ŗ': 'r',
            'Ř': 'R',
            'ř': 'r',
            'Ś': 'S',
            'ś': 's',
            'Ŝ': 'S',
            'ŝ': 's',
            'Ş': 'S',
            'ş': 's',
            'Š': 'S',
            'š': 's',
            'Ţ': 'T',
            'ţ': 't',
            'Ť': 'T',
            'ť': 't',
            'Ŧ': 'T',
            'ŧ': 't',
            'Ũ': 'U',
            'ũ': 'u',
            'Ū': 'U',
            'ū': 'u',
            'Ŭ': 'U',
            'ŭ': 'u',
            'Ů': 'U',
            'ů': 'u',
            'Ű': 'U',
            'ű': 'u',
            'Ų': 'U',
            'ų': 'u',
            'Ŵ': 'W',
            'ŵ': 'w',
            'Ŷ': 'Y',
            'ŷ': 'y',
            'Ÿ': 'Y',
            'Ź': 'Z',
            'ź': 'z',
            'Ż': 'Z',
            'ż': 'z',
            'Ž': 'Z',
            'ž': 'z',
            'ſ': 's',
            'ƒ': 'f',
            'Ơ': 'O',
            'ơ': 'o',
            'Ư': 'U',
            'ư': 'u',
            'Ǎ': 'A',
            'ǎ': 'a',
            'Ǐ': 'I',
            'ǐ': 'i',
            'Ǒ': 'O',
            'ǒ': 'o',
            'Ǔ': 'U',
            'ǔ': 'u',
            'Ǖ': 'U',
            'ǖ': 'u',
            'Ǘ': 'U',
            'ǘ': 'u',
            'Ǚ': 'U',
            'ǚ': 'u',
            'Ǜ': 'U',
            'ǜ': 'u',
            'Ǻ': 'A',
            'ǻ': 'a',
            'Ǽ': 'AE',
            'ǽ': 'ae',
            'Ǿ': 'O',
            'ǿ': 'o'
        };
        var res = '';
        if(str) {
            for (var i = 0; i < str.length; i++) {
                let c = str.charAt(i);
                res += map[c] || c;
            }
            return res;
        }
        return res;
    }

    /**
     * WIP delay handler
     */
    delay (callback, ms) {(
        (callback, ms) => {
            let timer = 0;
            return (callback, ms) => {
                clearTimeout(timer);
                timer = setTimeout(callback, ms);
            };
        })()
    }


    /**
     * JQuery function extensions
     */
    jQueryExtensions() {
        this.debug('JQuery extensions loaded')
        this.registerJqueryStyle()

        // Reverse array
        $.fn.reverse = [].reverse;

        // Search
        $.fn.search = function (query) {
            return $(this).find(query).add($(this).filter(query));
        }

        //Check use
        $.fn.getColor = function (x, y) {
            var img = $(this).get(0);
            var canvas = $("<canvas>");
            canvas = canvas.get(0);
            var context = canvas.getContext('2d');
            context.drawImage(img, 0, 0);
            data = context.getImageData(x, y, 1, 1).data;
            if($('isFama').length < 1){
                //delete canvas;
            }

            if (this.supportRGBA()) {
                var data = "rgba(" + data[0] + ", " + data[1] + ", " + data[2] + ", " + data[3] + ")";
            }
            else {
                var data = "rgb(" + data[0] + ", " + data[1] + ", " + data[2] + ")";
            }
            return data;
        }

        //imageLoad
        $.fn.imageLoad = function (fn) {
            return $(this).each(function () {
                if (this.complete) {
                    fn.call(this);
                    return;
                }
                this.onload = function () {
                    fn.call(this);
                }
                this.src = this.src;
            });
        }
    }

    getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1);
            if (c.indexOf(name) == 0)
                return c.substring(name.length, c.length);
        }
        return "";
    };

    /**
     *
     * @param {*} cname
     * @returns
     */
    checkCookie(cname) {
        var resul = false;
        var cookie = this.getCookie(cname);
        if (cookie!="") {
            resul = true;
        }
        return resul;
    };

    /**
     *
     * @param {*} cname
     * @param {*} cvalue
     * @param {*} exdays
     */
    setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + "; " + expires;
    };

    /**
     *
     * @returns
     */
    inMobileBody() {
        var inMobileBody = parseFloat($('body').css('width')) <= 991;
        return inMobileBody;
    }

    /**
     *
     */
    addGlobalExtensions () {

    }

    /**
     *
     * @param {*} sParam
     * @returns
     */
    getURLParameter(sParam) { //usa fama, famaliving
        this.debug('GetURLParameter');
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] == sParam) {
                return sParameterName[1];
            }
        }
    }

    /**
     *  @deprecated
     */
    registerJqueryStyle() {
         // style
        if ($.fn.style) {
            return;
        }

        // Escape regex chars with \
        var escape = function (text) {
            return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
        };

        // For those who need them (< IE 9), add support for CSS functions
        var isStyleFuncSupported = !!CSSStyleDeclaration.prototype.getPropertyValue;
        if (!isStyleFuncSupported) {
            CSSStyleDeclaration.prototype.getPropertyValue = function (a) {
                return this.getAttribute(a);
            };
            CSSStyleDeclaration.prototype.setProperty = function (styleName, value, priority) {
                this.setAttribute(styleName, value);
                var priority = typeof priority != 'undefined' ? priority : '';
                if (priority != '') {
                    // Add priority manually
                    var rule = new RegExp(escape(styleName) + '\\s*:\\s*' + escape(value) +
                        '(\\s*;)?', 'gmi');
                    this.cssText =
                        this.cssText.replace(rule, styleName + ': ' + value + ' !' + priority + ';');
                }
            };
            CSSStyleDeclaration.prototype.removeProperty = function (a) {
                return this.removeAttribute(a);
            };
            CSSStyleDeclaration.prototype.getPropertyPriority = function (styleName) {
                var rule = new RegExp(escape(styleName) + '\\s*:\\s*[^\\s]*\\s*!important(\\s*;)?',
                    'gmi');
                return rule.test(this.cssText) ? 'important' : '';
            }
        }

        //NOTE: Moved to FamaUtils -> Refactor code
        // The style function
        $.fn.style = function (styleName, value, priority) {
            // DOM node
            var node = this.get(0);
            // Ensure we have a DOM node
            if (typeof node == 'undefined') {
                return this;
            }
            // CSSStyleDeclaration
            var style = this.get(0).style;
            // Getter/Setter
            if (typeof styleName != 'undefined') {
                if (typeof value != 'undefined') {
                    // Set style property
                    priority = typeof priority != 'undefined' ? priority : '';
                    style.setProperty(styleName, value, priority);
                    return this;
                } else {
                    // Get style property
                    return style.getPropertyValue(styleName);
                }
            } else {
                // Get CSSStyleDeclaration
                return style;
            }
        };
    }

    supportRGBA() {
        var scriptElement = document.getElementsByTagName('script')[0];
        var prevColor = scriptElement.style.color;
        try {
            scriptElement.style.color = 'rgba(1,5,13,0.44)';
        } catch (e) {
        }
        var result = scriptElement.style.color != prevColor;
        scriptElement.style.color = prevColor;
        return result;
    }
}
